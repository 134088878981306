import { derive } from "rokay/prop/derive"
import { PropView } from "rokay/prop/prop"
import { pair, qsSetOf, qStr } from "rokay/route/query"
import { matchOpt } from "rokay/route/router"


export const
  pgUp = {
    render: <T>(cb: (key: string) => T) =>
      matchOpt(/^\/ups\/([^\/]+)\/?$/, (match) => cb(decodeURIComponent(match[1]))),
    str: (key: string) => `/ups/${encodeURIComponent(key)}`,
  },

  pgUpEdit = {
    render: <T>(cb: (key: string) => T) =>
      matchOpt(/^\/ups\/([^\/]+)\/edit\/?$/, (match) => cb(decodeURIComponent(match[1]))),
    str: (key: string) => `/ups/${encodeURIComponent(key)}/edit`,
  },

  pgUps = {
    render: <T>(
      cb: (qs: { access?: PropView<("DIRECT" | "HIDDEN" | "PRIVATE" | "PUBLIC")[] | undefined> }) => T,
    ) =>
      matchOpt(/^\/ups\/?$/, (_match, parsed) =>
        cb({
          access: derive(parsed, (parsed) => qsSetOfDIRECTHIDDENPRIVATEPUBLIC.parse(parsed.access)),
        })
      ),
    str: (qs: { access?: ("DIRECT" | "HIDDEN" | "PRIVATE" | "PUBLIC")[] } = {}) =>
      `/ups${qStr(pair("access", qs.access, qsSetOfDIRECTHIDDENPRIVATEPUBLIC.stringify))}`,
  },

  pgUpsNew = {
    render: <T>(cb: () => T) => matchOpt(/^\/ups\/new\/?$/, () => cb()),
    str: () => "/ups/new",
  },

  qsSetOfDIRECTHIDDENPRIVATEPUBLIC = qsSetOf("DIRECT", "HIDDEN", "PRIVATE", "PUBLIC")
