import { maxLength, notEmpty, validateAll } from "rokay/data/validation"


export type Up = {
  access: UpAccess
  data: UpData
  key: string
  name?: UpName
  uploaded: string
}

export type UpAccess = "DIRECT" | "HIDDEN" | "PUBLIC" | "PRIVATE"

export type UpData = { type: "VIDEO", version: 1 }

export type UpName = string

export type UpRequest = {
  access: UpAccess
  data: UpData
  name?: UpName
}

export type UpRequestYtdl = { access: UpAccess, url: string }


export const
  Up = (access: UpAccess, data: UpData, key: string, uploaded: string, name?: UpName): Up => ({
    access,
    data,
    key,
    uploaded,
    name,
  }),

  UpData = (): UpData => ({ type: "VIDEO", version: 1 }),

  UpRequest = (access: UpAccess, data: UpData, name?: UpName): UpRequest => ({
    access,
    data,
    name,
  }),

  UpRequestYtdl = (access: UpAccess, url: string): UpRequestYtdl => ({ access, url }),

  validateUpName = validateAll(notEmpty, maxLength(255)),

  validateUpRequestName = validateAll(notEmpty, maxLength(255))
