import { accept, multiple, placeholder, value } from "rokay/browser/attr"
import { apd } from "rokay/browser/core"
import { a, button, div, h2, input } from "rokay/browser/elt"
import { matchIf } from "rokay/browser/match"
import { onChange, onClick, onInput } from "rokay/browser/on"
import { $, bind } from "rokay/browser/prop"
import { Prop } from "rokay/prop/prop"

import { App } from "../../shared/app"
import { pgUp } from "../../shared/ups/pages.gen"
import { Up, UpAccess, UpRequest } from "../../shared/ups/types.gen"
import { ActionForm } from "../elts/action-form"
import { FormField } from "../elts/form-field"
import { $selectedIf } from "../style/ups.gen"

import { toUpRequest, UpRequestFM } from "./form-models.gen"


type UpArg = { t: "files", files: File[] } | { t: "ytdl", url: string }
type UpArgForm = { t: "files", files: Prop<File[]> } | { t: "ytdl", url: Prop<string> }


const
  ACCESS_BUTTONS: UpAccess[] = ["PUBLIC", "DIRECT", "PRIVATE", "HIDDEN"]


export const
  UpForm = (app: App, up: Up | undefined, action: (request: UpRequest, arg: UpArg) => Promise<any>) => {
    const
      AccessButton = (_access: UpAccess) =>
        button(apd(_access), $(fm.access, (a) => $selectedIf(a === _access)), onClick(() => {
          fm.access.set(_access)
        })),

      ArgButton = (type: "file" | "ytdl") =>
        button(apd(type), $(arg, (a) => $selectedIf(a.t === type)), onClick(() => {
          if (type === "file") {
            arg.set({ t: "files", files: Prop([]) })
          } else {
            arg.set({ t: "ytdl", url: Prop("") })
          }
        })),

      fm = UpRequestFM(up ?? UpRequest("HIDDEN", { type: "VIDEO", version: 1 }, undefined)),

      arg = Prop<UpArgForm>({ t: "files", files: Prop([]) })

    return ActionForm(
      { action: () => action(toUpRequest(fm), convert(arg.get())), errors: fm.errors },
      apd(
        up != null ?
          h2(apd("Edit ", a(app.router.href(pgUp.str(up.key)), apd(up.key))))
        :
          h2(apd("Make an Up")),

        up == null ?
          div(apd(
            div(apd(ArgButton("file"), ArgButton("ytdl"))),

            matchIf(arg, (_arg) =>
              _arg.t === "files" ?
                FormField("Files", {}, apd(
                  input("file", accept("video/*"), multiple, onChange(
                    ((el) => {
                      if (el.files == null) { return }
                      _arg.files.set(Array.from(el.files))
                    }),
                  )),
                ))
              :
                FormField("YTDL", {}, apd(input("text", bind(_arg.url), placeholder("URL..."))))
            ),
          ))
        :
          undefined,

        FormField("Name", { error: fm.name.error }, apd(
          input("text", $(fm.name.value, name => value(name ?? "")), placeholder("Up Name..."), onInput(el => {
            fm.name.value.set(el.value || undefined)
          })),
        )),

        FormField("Access", {}, apd(...ACCESS_BUTTONS.map((access) => AccessButton(access)))),
      ),
    )
  }


const
  convert = (arg: UpArgForm): UpArg =>
    arg.t === "files" ? { t: "files", files: arg.files.get() } : { t: "ytdl", url: arg.url.get() }


