import { postApd } from "rokay/browser/apd"
import { apd } from "rokay/browser/core"
import { a, code, div, h2, h3, p, pre } from "rokay/browser/elt"
import { height, overflow } from "rokay/browser/style"
import { Asink } from "rokay/prop/async"
import { Prop } from "rokay/prop/prop"

import { Job } from "../../shared/jobs/types.gen"
import { pgUp } from "../../shared/ups/pages.gen"
import { AppClient } from "../app"
import { Admin } from "../elts/admin.syn"
import { JobLink, SimpleLink } from "../elts/links"
import { LoaderProp } from "../elts/loader"
import { $mt1 } from "../style/utils.gen"

import { apiKeyGet } from "./apis.gen"


export const
  JobPage = (app: AppClient, id: string) =>
    Admin(app, () => {
      const
        job = Asink({
          gen: () =>
            apiKeyGet(id).then((_job) => {
              if (_job == null || _job.status === "FAILED" || _job.status === "SUCCEEDED") {
                return _job
              }
              setTimeout(
                () => {
                  job.refresh()
                },
                2000,
              )
              return _job
            }),
        }),

        lastJob = Prop(job.prop.get())

      job.prop
        .listen((job) => {
          if (job.t !== "data") { return }
          lastJob.set(job)
        })

      return div(apd(
        LoaderProp(lastJob, (job) =>
          job == null ? h2(apd("Job ", id, " Not Found")) : JobSection(app, job)
        ),
      ))
    }),

  JobSection = (app: AppClient, job: Job) =>
    div(apd(
      h3(apd("Job ", JobLink(app, job.id), `: ${job.status}`)),
      job.request.t === "reprocess" ?
        div(apd(
          p(apd(
            "Reprocessing ",
            a(app.router.href(pgUp.str(job.request.key)), apd(`Up ${job.request.key}`)),
          )),
          JobLogPre(job),
        ))
      : job.request.t === "ytdl" ?
        div(apd(p(apd("YTDLing ", SimpleLink(job.request.url))), JobLogPre(job)))
      :
        div(apd(p(apd("Request:", code(apd(JSON.stringify(job.request))))), JobLogPre(job))),
    ))


const
  JobLogPre = (job: Job) =>
    pre(
      apd(...job.logs.map((log) => div(apd(log.value)))),
      $mt1,
      height("500px"),
      overflow("auto"),
      postApd((el) => {
        el.scrollTop = el.scrollHeight
      }),
    )


