import { getUserAsink } from "okay-site-login/client/users/service"
import { apd } from "rokay/browser/core"
import { code, h2 } from "rokay/browser/elt"
import { mount } from "rokay/browser/mount"
import { BrowserRouter } from "rokay/browser/router"
import { Visible } from "rokay/browser/visible"

import { pgJob, pgJobs } from "../shared/jobs/pages.gen"
import { pgIndex } from "../shared/pages.gen"
import { pgUp, pgUpEdit, pgUpsNew } from "../shared/ups/pages.gen"

import { apiLogPost } from "./apis.gen"
import { AppClient } from "./app"
import { Base } from "./base.syn"
import { JobPage } from "./jobs/job"
import { Jobs } from "./jobs/jobs"
import { EditUpPage } from "./ups/edit"
import { UpsNew } from "./ups/new"
import { UpPage } from "./ups/up.syn"
import { Ups } from "./ups/ups"


mount(document.body, () => {
  const
    router = BrowserRouter(),
    app: AppClient = {
      router,
      user: getUserAsink(),
      visible: Visible(),
    }

  return Base(app, router.match(
    [
      pgIndex.render(() => Ups(app)),
      pgUpsNew.render(() => UpsNew(app)),
      pgUp.render((key) => {
        try {
          return UpPage(app, key)
        } catch (e) { apiLogPost({ message: (e as any).message }) }
      }),
      pgUpEdit.render((key) => EditUpPage(app, key)),
      pgJobs.render(() => Jobs(app)),
      pgJob.render((id) => JobPage(app, id)),
    ],
    (path) => h2(apd("Page ", code(apd(path)), " Not Found")),
  ))
})


