import { controls, poster, preload, src } from "rokay/browser/attr"
import { onDestroy } from "rokay/browser/capture"
import { apd } from "rokay/browser/core"
import { a, div, h2, small, span, video } from "rokay/browser/elt"
import { match } from "rokay/browser/match"
import { $ } from "rokay/browser/prop"
import { margin } from "rokay/browser/style"
import { Asink } from "rokay/prop/async"
import { Prop } from "rokay/prop/prop"

import { App } from "../../shared/app"
import { apiUpFileGet, apiUpPosterGet } from "../../shared/ups/apis.gen"
import { pgUpEdit } from "../../shared/ups/pages.gen"
import { User } from "../elts/user.syn"
import { $maxw100 } from "../style/utils.gen"

import { apiKeyGet } from "./apis.gen"


export const
  UpPage = (app: App, key: string) =>
    User(app, () => {
      const
        videoSrc = Prop(apiUpFileGet(key)),
        up = Asink({ gen: () => apiKeyGet(key) })

      return div(apd(
        h2(apd(
          match(up.prop, (up) => span(apd(up.t === "data" ? up.data.name ?? key : key))),
          small(apd(" ", a(app.router.href(pgUpEdit.str(key)), apd("(Edit)")))),
        )),
        video(
          $maxw100,
          $(videoSrc, src),
          controls,
          margin("1em auto 0"),
          poster(apiUpPosterGet(key)),
          preload("auto"),
          apd("Your browser does not support this video."),
          () => {
            onDestroy(() => {
              videoSrc.set("")
            })
          },
        ),
      ))
    })


