import { href, target } from "rokay/browser/attr"
import { apd } from "rokay/browser/core"
import { a, button, code, div, h2, pre, section, span } from "rokay/browser/elt"
import { mapHTML } from "rokay/browser/map"
import { match, matchIf } from "rokay/browser/match"
import { onClick } from "rokay/browser/on"
import { margin, maxWidth } from "rokay/browser/style"
import { remove } from "rokay/data/array"
import { Asink } from "rokay/prop/async"
import { derive } from "rokay/prop/derive"
import { Prop } from "rokay/prop/prop"

import { pgJob } from "../../shared/jobs/pages.gen"
import { pgUp } from "../../shared/ups/pages.gen"
import { UpRequest } from "../../shared/ups/types.gen"
import { AppClient } from "../app"
import { EmptyP } from "../elts/empty.syn"
import { User } from "../elts/user.syn"
import { $mt1 } from "../style/utils.gen"

import { apiPost, apiYtdlPost } from "./apis.gen"
import { UpForm } from "./form"


type ActiveUpload = ActiveFile | ActiveYTDL
type ActiveFile = {
  t: "file"
  file: File
  request: UpRequest
}
type ActiveYTDL = {
  t: "ytdl"
  request: UpRequest
  url: string
}


export const
  UpsNew = (app: AppClient) =>
    User(app, () => {
      const
        activeUploads = Prop<ActiveUpload[]>([]),
        showActive = derive(activeUploads, (ups) => ups.length > 0)

      return div(margin("0 auto"), maxWidth("900px"), apd(
        UpForm(app, undefined, (request, arg) => {
          if (arg.t === "files") {
            activeUploads.set(activeUploads.get()
              .concat(arg.files
                .map((file) => ({
                  t: "file",
                  request,
                  file,
                }))))
          } else {
            activeUploads.set(activeUploads.get()
              .concat(
                {
                  t: "ytdl",
                  request,
                  url: arg.url,
                },
              ))
          }
          return Promise.resolve()
        }),

        matchIf(showActive, () =>
          section($mt1, apd(
            h2(apd("Active Ups")),
            div(mapHTML(
              activeUploads,
              (up) =>
                up.t === "file" ?
                  ActiveFileUp(app, up, () => {
                    activeUploads.set(remove(activeUploads.get(), up))
                  })
                :
                  ActiveYTDLUp(app, up, () => {
                    activeUploads.set(remove(activeUploads.get(), up))
                  })
              ,
              EmptyP(apd("None")),
            )),
          ))
        ),
      ))
    })


const
  ActiveFileUp = ({ router }: AppClient, up: ActiveFile, clear: () => void) => {
    const asink = Asink({ gen: () => apiPost({ ...up.request, upload: up.file }) })

    return div(apd(
      match(asink.prop, (prop) =>
        prop.t === "load" ?
          span(apd(`Uploading File ${up.file.name}...`))
        : prop.t === "error" ?
          pre(apd(
            `Error Uploading File ${
              up.file.name
            }:
${
              prop.error.message ?? JSON.stringify(prop.error, null, 2)
            }`,
            button(onClick(asink.refresh), apd("Retry")),
          ))
        :
          div(apd(
            "Uploaded File ",
            code(apd(up.file.name)),
            " as ",
            a(router.href(pgUp.str(prop.data.key)), apd(prop.data.key)),
            ". ",
            button(onClick(clear), apd("Clear")),
          ))
      ),
    ))
  },

  ActiveYTDLUp = ({ router }: AppClient, up: ActiveYTDL, clear: () => void) => {
    const asink = Asink({ gen: () => apiYtdlPost({ access: up.request.access, url: up.url }) })

    return div(apd(
      match(asink.prop, (prop) =>
        prop.t === "load" ?
          span(apd(`YTDLing ${up.url}...`))
        : prop.t === "error" ?
          pre(apd(
            `Error YTDLing ${up.url}:
${prop.error.message ?? JSON.stringify(prop.error, null, 2)} `,
            button(onClick(asink.refresh), apd("Retry")),
          ))
        :
          div(apd(
            "YTDLed ",
            a(href(up.url), target("_blank"), apd(up.url)),
            " as ",
            a(router.href(pgJob.str(prop.data.id)), apd(prop.data.id)),
            ". ",
            button(onClick(clear), apd("Clear")),
          ))
      ),
    ))
  }


