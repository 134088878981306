import { cls, clsIf } from "rokay/browser/core"


export const
  $flex = cls("flex"),
  $flexIf = clsIf("flex"),
  $fsItalic = cls("fs-italic"),
  $fsItalicIf = clsIf("fs-italic"),
  $grid = cls("grid"),
  $gridIf = clsIf("grid"),
  $m1 = cls("m-1"),
  $m1If = clsIf("m-1"),
  $maxw100 = cls("maxw-100"),
  $maxw100If = clsIf("maxw-100"),
  $mb1 = cls("mb-1"),
  $mb1If = clsIf("mb-1"),
  $ml1 = cls("ml-1"),
  $ml1If = clsIf("ml-1"),
  $mt1 = cls("mt-1"),
  $mt1If = clsIf("mt-1"),
  $pin = cls("pin"),
  $pinIf = clsIf("pin"),
  $relative = cls("relative"),
  $relativeIf = clsIf("relative")
