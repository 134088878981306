import { derive } from "rokay/prop/derive"
import { PropView } from "rokay/prop/prop"
import { pair, qsSetOf, qStr } from "rokay/route/query"
import { matchOpt } from "rokay/route/router"


export const
  pgJob = {
    render: <T>(cb: (key: string) => T) =>
      matchOpt(/^\/jobs\/([^\/]+)\/?$/, (match) => cb(decodeURIComponent(match[1]))),
    str: (key: string) => `/jobs/${encodeURIComponent(key)}`,
  },

  pgJobs = {
    render: <T>(
      cb: (
        qs: { status?: PropView<("ACCEPTED" | "RUNNING" | "SUCCEEDED" | "FAILED")[] | undefined> },
      ) => T,
    ) =>
      matchOpt(/^\/jobs\/?$/, (_match, parsed) =>
        cb({
          status: derive(parsed, (parsed) =>
            qsSetOfACCEPTEDRUNNINGSUCCEEDEDFAILED.parse(parsed.status)
          ),
        })
      ),
    str: (qs: { status?: ("ACCEPTED" | "RUNNING" | "SUCCEEDED" | "FAILED")[] } = {}) =>
      `/jobs${qStr(pair("status", qs.status, qsSetOfACCEPTEDRUNNINGSUCCEEDEDFAILED.stringify))}`,
  },

  qsSetOfACCEPTEDRUNNINGSUCCEEDEDFAILED = qsSetOf("ACCEPTED", "RUNNING", "SUCCEEDED", "FAILED")
