import { href } from "rokay/browser/attr"
import { apd } from "rokay/browser/core"
import { a } from "rokay/browser/elt"

import { App } from "../../shared/app"
import { pgJob } from "../../shared/jobs/pages.gen"


export const
  JobLink = ({ router }: App, id: string) =>
    a(router.href(pgJob.str(id)), apd(id)),

  SimpleLink = (url: string) =>
    a(href(url), apd(url))
