import { checked } from "rokay/browser/attr"
import { apd } from "rokay/browser/core"
import { div, h2, input, label, section } from "rokay/browser/elt"
import { onChange } from "rokay/browser/on"
import { $ } from "rokay/browser/prop"
import { marginBottom } from "rokay/browser/style"
import { Asink, cached } from "rokay/prop/async"
import { Prop } from "rokay/prop/prop"

import { AppClient } from "../app"
import { EmptyP } from "../elts/empty.syn"
import { LoaderProp } from "../elts/loader"
import { User } from "../elts/user.syn"
import { $grid } from "../style/utils.gen"

import { apiGet } from "./apis.gen"
import { UpSection } from "./up-section"


export const
  Ups = (app: AppClient) =>
    User(app, () => {
      const
        showHidden = Prop(false).listen(() => ups.refresh()),
        ups = Asink({
          gen: () =>
            apiGet({
              access: showHidden.get() ? ["DIRECT", "PRIVATE", "PUBLIC", "HIDDEN"] : undefined,
            }),
        })

      return div(apd(
        section(apd(
          h2(apd("Your Ups")),
          div(marginBottom("1em"), apd(
            label(apd(
              input("checkbox", $(showHidden, checked), onChange((el) => {
                showHidden.set(el.checked)
              })),
              " Show Hidden",
            )),
          )),
          LoaderProp(cached(ups.prop), (_ups) =>
            _ups.length === 0 ?
              EmptyP(apd("You have no ups."))
            :
              div($grid, apd(
                ..._ups.sort((a, b) => b.uploaded.localeCompare(a.uploaded))
                  .map((up) => UpSection(app, up, () => ups.refresh()))
              ))
          ),
        )),
      ))
    })


