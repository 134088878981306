import { disabled as disabledAttr } from "rokay/browser/attr"
import { apd, text } from "rokay/browser/core"
import { button, div, form, submit as submitElt } from "rokay/browser/elt"
import { match } from "rokay/browser/match"
import { onClick, onSubmit } from "rokay/browser/on"
import { $ } from "rokay/browser/prop"
import { gap } from "rokay/browser/style"
import { MixArgs } from "rokay/mix"
import { AsyncAction } from "rokay/prop/async"
import { derive } from "rokay/prop/derive"
import { Prop, PropConst, PropView } from "rokay/prop/prop"
import { Tuple } from "rokay/prop/tuple"

import { $flex } from "../style/utils.gen"

import { ErrorPre } from "./error-pre"


export const
  ActionForm = <T>(
    {
      action,
      cancel,
      errors = PropConst([]),
    }: {
      action(): Promise<T>
      cancel?: () => void
      errors?: PropView<string[]>
    },
    ...args: MixArgs<HTMLFormElement>
  ) => {
    const
      prop = Prop<AsyncAction>({ t: "idle" }),
      loading = derive(prop, (prop) => prop.t === "load"),
      disabled = derive(Tuple(errors, loading), ([errors, loading]) => errors.length > 0 || loading)

    return form(
      onSubmit(() => {
        const _errors = errors.get()
        if (_errors.length > 0) {
          prop.set({
            t: "error",
            error: { errors: _errors },
          })
          return
        }
        prop.set({ t: "load" })
        return action().then(
          () => {
            prop.set({ t: "idle" })
          },
          (error) => {
            prop.set({ t: "error", error })
          },
        )
      }),
      ...args,
      apd(
        div($flex, gap("1em"), apd(
          submitElt($(disabled, disabledAttr), $(prop, (_prop) =>
            text(_prop.t === "load" ? "Submitting..." : "Submit")
          )),
          cancel ? button($(loading, disabledAttr), apd("Cancel"), onClick(cancel)) : undefined,
        )),
        match(prop, (_action) => _action.t === "error" ? ErrorPre(_action.error) : undefined),
      ),
    )
  }


