import { apd, text } from "rokay/browser/core"
import { button, div } from "rokay/browser/elt"
import { onClick } from "rokay/browser/on"
import { Asink } from "rokay/prop/async"

import { pgJobs } from "../../shared/jobs/pages.gen"
import { JobRequestReprocessAll } from "../../shared/jobs/types.gen"
import { AppClient } from "../app"
import { Admin } from "../elts/admin.syn"
import { EmptyP } from "../elts/empty.syn"
import { Loader } from "../elts/loader"

import { apiGet, apiPost } from "./apis.gen"
import { JobSection } from "./job"


export const
  Jobs = (app: AppClient) =>
    Admin(app, () => {
      const jobs = Asink({ gen: apiGet })

      return div(apd(
        button(text("Reprocess All"), onClick(() => {
          apiPost({ request: JobRequestReprocessAll() }).then(() => {
            app.router.push(pgJobs.str())
          })
        })),
        Loader(jobs, (jobs) =>
          jobs.length === 0 ?
            EmptyP(apd("There are no Jobs."))
          :
            div(apd(...jobs.map((job) => JobSection(app, job))))
        ),
      ))
    })


