import { matchUser } from "okay-site-login/client/users/match.syn"
import { AuthUser } from "okay-site-login/shared/users/types.gen"
import { apd } from "rokay/browser/core"
import { div, h2 } from "rokay/browser/elt"

import { App } from "../../shared/app"
import { $m1 } from "../style/utils.gen"


export const
  User = ({ user }: App, render: (user: AuthUser) => Node) =>
    div($m1, apd(
      matchUser(user, user =>
        user.type === "ANONYMOUS" ?
          h2(apd("You are not Authorized to Access this Page."))
        :
          render(user)
      )
    ))
