import { filterNotNil } from "rokay/data/array"
import { validateOptional } from "rokay/data/validation"
import { derive } from "rokay/prop/derive"
import { PropForm } from "rokay/prop/form"
import { Prop, PropView } from "rokay/prop/prop"
import { Tuple } from "rokay/prop/tuple"

import { Up, UpAccess, UpData, UpName, UpRequest, validateUpName, validateUpRequestName } from "../../shared/ups/types.gen.js"

import { UpRequestCreate, validateUpRequestCreateName } from "./types.gen.js"


export type UpFM = {
  access: Prop<UpAccess>
  data: { type: "VIDEO", version: 1 }
  key: string
  name: PropForm<UpName | undefined, NonNullable<ReturnType<typeof validateUpName>>>
  uploaded: string
  errors: PropView<string[]>
  invalid: PropView<boolean>
}

export type UpRequestCreateFM = {
  access: Prop<UpAccess>
  data: UpData
  name: PropForm<UpName | undefined, NonNullable<ReturnType<typeof validateUpRequestCreateName>>>
  upload: File
  errors: PropView<string[]>
  invalid: PropView<boolean>
}

export type UpRequestFM = {
  access: Prop<UpAccess>
  data: { type: "VIDEO", version: 1 }
  name: PropForm<UpName | undefined, NonNullable<ReturnType<typeof validateUpRequestName>>>
  errors: PropView<string[]>
  invalid: PropView<boolean>
}


export const
  toUp = (fm: UpFM): Up => ({
    access: fm.access.get(),
    data: fm.data,
    key: fm.key,
    uploaded: fm.uploaded,
    name: fm.name.value.get(),
  }),

  toUpRequest = (fm: UpRequestFM): UpRequest => ({
    access: fm.access.get(),
    data: fm.data,
    name: fm.name.value.get(),
  }),

  toUpRequestCreate = (fm: UpRequestCreateFM): UpRequestCreate => ({
    access: fm.access.get(),
    data: fm.data,
    upload: fm.upload,
    name: fm.name.value.get(),
  }),

  UpFM = (model: Up): UpFM => {
    const
      access = Prop(model.access),
      name = PropForm(model.name, validateOptional(validateUpName)),

      errors = derive(Tuple(name.error), filterNotNil)
    return ({
      access,
      data: model.data,
      key: model.key,
      uploaded: model.uploaded,
      name,
      errors,
      invalid: derive(errors, (errors) => errors.length > 0),
    })
  },

  UpRequestCreateFM = (model: UpRequestCreate): UpRequestCreateFM => {
    const
      access = Prop(model.access),
      name = PropForm(model.name, validateOptional(validateUpRequestCreateName)),

      errors = derive(Tuple(name.error), filterNotNil)
    return ({
      access,
      data: model.data,
      upload: model.upload,
      name,
      errors,
      invalid: derive(errors, (errors) => errors.length > 0),
    })
  },

  UpRequestFM = (model: UpRequest): UpRequestFM => {
    const
      access = Prop(model.access),
      name = PropForm(model.name, validateOptional(validateUpRequestName)),

      errors = derive(Tuple(name.error), filterNotNil)
    return ({
      access,
      data: model.data,
      name,
      errors,
      invalid: derive(errors, (errors) => errors.length > 0),
    })
  }
