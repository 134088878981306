import { disabled, height, src } from "rokay/browser/attr"
import { apd, text } from "rokay/browser/core"
import { a, button, div, img, section } from "rokay/browser/elt"
import { onClick } from "rokay/browser/on"
import { $ } from "rokay/browser/prop"
import { backgroundColor, margin, size } from "rokay/browser/style"
import { Prop } from "rokay/prop/prop"

import { App } from "../../shared/app"
import { apiUpThumbnailGet } from "../../shared/ups/apis.gen"
import { pgUp } from "../../shared/ups/pages.gen"
import { Up } from "../../shared/ups/types.gen"
import { $flex, $pin, $relative } from "../style/utils.gen"

import { apiKeyDelete, apiKeyReprocessPut } from "./apis.gen"


export const
  UpSection = ({ router }: App, up: Up, onDelete: () => void) => {
    const
      deleting = Prop(false),
      reprocessing = Prop(false)

    return section(backgroundColor("#999"), $flex, $relative, size("256px", "144px"), apd(
      img(height(144), margin("0 auto"), src(apiUpThumbnailGet(up.key))),
      div($pin, apd(
        a(router.href(pgUp.str(up.key)), apd(up.name ?? up.key)),
        " ",
        button(
          $(reprocessing, disabled),
          $(reprocessing, (reprocessing) => text(reprocessing ? "Reprocessing..." : "Reprocess")),
          onClick(() => {
            reprocessing.set(true)
            apiKeyReprocessPut(up.key).finally(() => {
              reprocessing.set(false)
            })
          }),
        ),
        " ",
        button(
          $(deleting, disabled),
          $(deleting, (deleting) => text(deleting ? "Deleting..." : "Delete")),
          onClick(() => {
            deleting.set(true)
            apiKeyDelete(up.key).then(onDelete)
              .finally(() => {
                deleting.set(false)
              })
          }),
        ),
      )),
    ))
  }


