import { Asink } from "rokay/prop/async"

import { App } from "../../shared/app"
import { Loader } from "../elts/loader"
import { User } from "../elts/user.syn"

import { apiKeyGet, apiKeyPut } from "./apis.gen"
import { UpForm } from "./form"


export const
  EditUpPage = (app: App, key: string) =>
    User(app, () => {
      const up = Asink({ gen: () => apiKeyGet(key) })

      return Loader(up, (_up) => UpForm(app, _up, (request) => apiKeyPut(key, request)))
    })


