import { matchUser } from "okay-site-login/client/users/match.syn"
import { apiRedirectsLoginGet, apiRedirectsLogoutGet } from "okay-site-login/shared/redirects/apis.gen"
import { href } from "rokay/browser/attr"
import { apd, ApdArg } from "rokay/browser/core"
import { a, div, footer, h1, header, span } from "rokay/browser/elt"
import { $ } from "rokay/browser/prop"

import { App } from "../shared/app"
import { pgJobs } from "../shared/jobs/pages.gen"
import { pgIndex } from "../shared/pages.gen"
import { pgUpsNew } from "../shared/ups/pages.gen"

import { $ml1 } from "./style/utils.gen"


export const
  Base = ({ router, user }: App, ...args: ApdArg[]) =>
    apd(
      header(apd(
        h1(apd(
          a(router.href(pgIndex.str()), apd("Ups"))
        )),

        matchUser(user, user =>
          user.type === "ANONYMOUS" ?
            div(apd(
              span($ml1, apd(
                a($(router.locationHref, location => href(apiRedirectsLoginGet({ redirect: location }))), apd(
                  "Login"
                ))
              ))
            ))
          :
            div(apd(
              span($ml1, apd(
                a(router.href(pgUpsNew.str()), apd("Up a Vid"))
              )),

              span($ml1, apd(
                a(router.href(pgJobs.str()), apd("Jobs"))
              )),

              span($ml1, apd("Logged in as ", user.name)),

              span($ml1, apd(
                a($(router.locationHref, location => href(apiRedirectsLogoutGet({ redirect: location }))), apd(
                  "Logout"
                ))
              ))
            ))
        )
      )),

      ...args,

      footer(apd("Created by Daniel Bucci in 2022.")),
    )
