import { apd } from "rokay/browser/core"
import { pre } from "rokay/browser/elt"
import { asArray, asObject2, asString, required2 } from "rokay/data/validation"


export const
  ErrorPre = (error: unknown) => {
    try { return pre(apd(parseSimpleError(error).message)) } catch (_e) {}
    try { return pre(apd(parseMultipleErrors(error).errors.join("\n"))) } catch (_e) {}
    return pre(apd(JSON.stringify(error, null, 2)))
  }


const
  parseMultipleErrors = asObject2({ errors: required2(asArray(asString)) }),

  parseSimpleError = asObject2(({ message: required2(asString) }))


