import { req, statusIs, toFD } from "rokay/browser/http"
import { pair, qStr } from "rokay/route/query"

import { Job } from "../../shared/jobs/types.gen.js"
import { qsSetOfDIRECTHIDDENPRIVATEPUBLIC } from "../../shared/ups/pages.gen.js"
import { Up, UpRequest, UpRequestYtdl } from "../../shared/ups/types.gen.js"

import { UpRequestCreate } from "./types.gen.js"


export const
  apiGet = (qs: { access?: ("DIRECT" | "HIDDEN" | "PRIVATE" | "PUBLIC")[] } = {}) =>
    req("get", `/ups${qStr(pair("access", qs.access, qsSetOfDIRECTHIDDENPRIVATEPUBLIC.stringify))}`).then<(Up)[]>(
      statusIs(200),
    ),

  apiKeyDelete = (key: string) =>
    req("delete", `/ups/${encodeURIComponent(key)}`).then<void>(statusIs(200)),

  apiKeyGet = (key: string) =>
    req("get", `/ups/${encodeURIComponent(key)}`).then<Up>(statusIs(200)),

  apiKeyPut = (key: string, body: UpRequest) =>
    req("put", `/ups/${encodeURIComponent(key)}`, body).then<Up>(statusIs(200)),

  apiKeyReprocessPut = (key: string) =>
    req("put", `/ups/${encodeURIComponent(key)}/reprocess`).then<Job>(statusIs(200)),

  apiPost = (body: UpRequestCreate) =>
    req("post", "/ups", toFD(body)).then<Up>(statusIs(200)),

  apiYtdlPost = (body: UpRequestYtdl) =>
    req("post", "/ups/ytdl", body).then<Job>(statusIs(200))
