import { UpAccess } from "../ups/types.gen.js"


export type Job = {
  id: string
  logs: (JobLog)[]
  request: JobRequest
  status: JobStatus
  userId: string
}

export type JobLog = JobLogError | JobLogLog | JobLogStderr | JobLogStdout

export type JobLogError = { t: "error", value: string }

export type JobLogLog = { t: "log", value: string }

export type JobLogStderr = { t: "stderr", value: string }

export type JobLogStdout = { t: "stdout", value: string }

export type JobRequest = 
  | JobRequestCleanTemp
  | JobRequestReprocess
  | JobRequestReprocessAll
  | JobRequestYtdl

export type JobRequestCleanTemp = { t: "cleanTemp" }

export type JobRequestReprocess = { t: "reprocess", key: string }

export type JobRequestReprocessAll = { t: "reprocessAll" }

export type JobRequestYtdl = {
  t: "ytdl"
  access: UpAccess
  url: string
}

export type JobStatus = "ACCEPTED" | "RUNNING" | "SUCCEEDED" | "FAILED"


export const
  Job = (id: string, logs: (JobLog)[], request: JobRequest, status: JobStatus, userId: string): Job => ({
    id,
    logs,
    request,
    status,
    userId,
  }),

  JobLogError = (value: string): JobLogError => ({ t: "error", value }),

  JobLogLog = (value: string): JobLogLog => ({ t: "log", value }),

  JobLogStderr = (value: string): JobLogStderr => ({ t: "stderr", value }),

  JobLogStdout = (value: string): JobLogStdout => ({ t: "stdout", value }),

  JobRequestCleanTemp = (): JobRequestCleanTemp => ({ t: "cleanTemp" }),

  JobRequestReprocess = (key: string): JobRequestReprocess => ({ t: "reprocess", key }),

  JobRequestReprocessAll = (): JobRequestReprocessAll => ({ t: "reprocessAll" }),

  JobRequestYtdl = (access: UpAccess, url: string): JobRequestYtdl => ({
    t: "ytdl",
    access,
    url,
  })
