import { apd } from "rokay/browser/core"
import { div, label } from "rokay/browser/elt"
import { matchIf } from "rokay/browser/match"
import { MixArgs } from "rokay/mix"
import { PropView } from "rokay/prop/prop"

import { $mb1 } from "../style/utils.gen"


export const
  FormField = (
    _label: string,
    { error }: { error?: PropView<string | undefined> },
    ...args: MixArgs<HTMLDivElement>
  ) =>
    div($mb1, apd(label(apd(_label))), ...args, apd(
      error != null ? matchIf(error, (error) => div(apd(error))) : undefined,
    ))


