import { req, statusIs } from "rokay/browser/http"
import { pair, qStr } from "rokay/route/query"

import { qsSetOfACCEPTEDRUNNINGSUCCEEDEDFAILED } from "../../shared/jobs/pages.gen.js"
import { Job, JobRequest } from "../../shared/jobs/types.gen.js"


export const
  apiGet = (qs: { status?: ("ACCEPTED" | "RUNNING" | "SUCCEEDED" | "FAILED")[] } = {}) =>
    req(
      "get",
      `/jobs${qStr(pair("status", qs.status, qsSetOfACCEPTEDRUNNINGSUCCEEDEDFAILED.stringify))}`,
    ).then<(Job)[]>(statusIs(200)),

  apiKeyGet = (key: string) =>
    req("get", `/jobs/${encodeURIComponent(key)}`).then<Job | undefined>(statusIs(200)),

  apiPost = (body: { request: JobRequest }) =>
    req("post", "/jobs", body).then<Job>(statusIs(200))
